export default [
  // Home page
  {
    path: '/',
    title: 'sjx h5 project',
    component: 'Home',
    exact: true
  },
  {
    path: '/service_agreement',
    title: '服务协议',
    component: 'ServiceAgreement',
    exact: true
  },
  {
    path: '/service_agreement_yigeguanjia',
    title: '服务协议',
    component: 'ServiceAgreement',
    exact: true
  },
  {
    path: '/app_download',
    title: '应用下载',
    component: 'AppDownload',
    exact: true
  },
  {
    path: '/app_download_sms',
    title: '应用下载',
    component: 'AppDownload/SMS',
    exact: true
  },
  {
    path: '/submit_complaint',
    title: '申诉问题提交',
    component: 'SubmitComplaint',
    exact: true
  },
  {
    path: '/material',
    title: '素材详情',
    component: 'MaterialDetail',
    exact: true
  },
  {
    path: '/about_us',
    title: '关于我们',
    component: 'About',
    exact: true
  },
  {
    path: '/test',
    title: '测试页面',
    component: 'Test',
    exact: true
  },
  {
    path: '/test-communicate',
    title: 'app-h5通信测试',
    component: 'TestCommunicate',
    exact: true,
    routes: [
      {
        path: '/test-communicate/page',
        title: 'app-h5通信测试二级页面',
        component: 'TestCommunicate/TestCommunicateSecondPage'
      }
    ]
  },
  {
    path: '/help',
    title: '帮助中心',
    component: 'Help',
    exact: true,
    routes: [
      {
        path: '/help/detail',
        title: '帮助详情',
        component: 'Help/HelpDetail',
        exact: true
      }
    ]
  },
  {
    path: '/invokeapp',
    title: '打开app中转页',
    component: 'Invokeapp',
    exact: true
  },
  {
    path: '/convention',
    title: '网络社区自律公约',
    component: 'Convention',
    exact: true
  },
  {
    path: '/userprivacy',
    title: '隐私政策',
    component: 'Userprivacy',
    exact: true
  }
]
