import React, { Suspense, Component } from 'react'
import { Router } from 'react-router-dom'
import { loadableRoutes } from '@/utils/route'
import { Route, Switch } from 'react-router-dom'
import history from './utils/history'

/**
 * 路由导入
 * fallback为异步加载路由组件时的ui loading component，后期可以优化
 */
const routes = (
  <Suspense fallback={<div />}>
    <Switch>
      {loadableRoutes.map(({ component: Comp, ...route }: any, i) => (
        <Route {...route} key={i} render={props => <Comp {...props} />} />
      ))}
      {/* 404page */}
      <Route render={() => <div>this is 404 page</div>} />
    </Switch>
  </Suspense>
)
class App extends Component<any, any> {
  render() {
    return (
      <div className="App">
        <Router history={history}>{routes}</Router>
      </div>
    )
  }
}

export default App
