import { lazy } from 'react'
import routes from '@/router.config'

// init React.lazy Comp
function LazyLoader(props: any) {
  return lazy(() => import(`@/containers/${props}`))
}

// init menu config be react router config style
function processRoutesLoadable(routes: any): [] {
  routes.forEach((route: any, index: number) => {
    if (route.component && typeof route.component === 'string') {
      route.component = LazyLoader(route.component)
    }
  })
  return routes
}
// flatten routes
function generatorRoutes(arr: any): [] {
  const rs: any = []
  function cyc(routes: []) {
    return routes.forEach((route: any) => {
      if (route.routes) {
        cyc(route.routes)
        delete route.routes
      }
      rs.push(route)
    })
  }
  cyc(arr)
  return rs
}
// 路由扁平数据
export const flattenRoutes = generatorRoutes(routes)
export const loadableRoutes = processRoutesLoadable(flattenRoutes)
// 路由path -> title 映射
export const routeTitles = flattenRoutes.reduce((a: any, v: any) => {
  if (v.title) a[v.path] = v.title
  return a
}, {})
